// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("./../../../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-agencydigital-js": () => import("./../../../src/pages/agencydigital.js" /* webpackChunkName: "component---src-pages-agencydigital-js" */),
  "component---src-pages-agencymodern-js": () => import("./../../../src/pages/agencymodern.js" /* webpackChunkName: "component---src-pages-agencymodern-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-appclassic-js": () => import("./../../../src/pages/appclassic.js" /* webpackChunkName: "component---src-pages-appclassic-js" */),
  "component---src-pages-appcreative-2-js": () => import("./../../../src/pages/appcreative2.js" /* webpackChunkName: "component---src-pages-appcreative-2-js" */),
  "component---src-pages-appcreative-js": () => import("./../../../src/pages/appcreative.js" /* webpackChunkName: "component---src-pages-appcreative-js" */),
  "component---src-pages-appminimal-js": () => import("./../../../src/pages/appminimal.js" /* webpackChunkName: "component---src-pages-appminimal-js" */),
  "component---src-pages-appmodern-js": () => import("./../../../src/pages/appmodern.js" /* webpackChunkName: "component---src-pages-appmodern-js" */),
  "component---src-pages-charity-js": () => import("./../../../src/pages/charity.js" /* webpackChunkName: "component---src-pages-charity-js" */),
  "component---src-pages-crypto-js": () => import("./../../../src/pages/crypto.js" /* webpackChunkName: "component---src-pages-crypto-js" */),
  "component---src-pages-cryptomodern-js": () => import("./../../../src/pages/cryptomodern.js" /* webpackChunkName: "component---src-pages-cryptomodern-js" */),
  "component---src-pages-donation-js": () => import("./../../../src/pages/donation.js" /* webpackChunkName: "component---src-pages-donation-js" */),
  "component---src-pages-fooddelivery-js": () => import("./../../../src/pages/fooddelivery.js" /* webpackChunkName: "component---src-pages-fooddelivery-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-hostingmodern-js": () => import("./../../../src/pages/hostingmodern.js" /* webpackChunkName: "component---src-pages-hostingmodern-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interior-js": () => import("./../../../src/pages/interior.js" /* webpackChunkName: "component---src-pages-interior-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-ride-js": () => import("./../../../src/pages/ride.js" /* webpackChunkName: "component---src-pages-ride-js" */),
  "component---src-pages-saas-js": () => import("./../../../src/pages/saas.js" /* webpackChunkName: "component---src-pages-saas-js" */),
  "component---src-pages-saasappcreative-js": () => import("./../../../src/pages/saasappcreative.js" /* webpackChunkName: "component---src-pages-saasappcreative-js" */),
  "component---src-pages-saasappdark-js": () => import("./../../../src/pages/saasappdark.js" /* webpackChunkName: "component---src-pages-saasappdark-js" */),
  "component---src-pages-saasclassic-js": () => import("./../../../src/pages/saasclassic.js" /* webpackChunkName: "component---src-pages-saasclassic-js" */),
  "component---src-pages-saascreative-js": () => import("./../../../src/pages/saascreative.js" /* webpackChunkName: "component---src-pages-saascreative-js" */),
  "component---src-pages-saasminimal-2-js": () => import("./../../../src/pages/saasminimal2.js" /* webpackChunkName: "component---src-pages-saasminimal-2-js" */),
  "component---src-pages-saasminimal-js": () => import("./../../../src/pages/saasminimal.js" /* webpackChunkName: "component---src-pages-saasminimal-js" */),
  "component---src-pages-saasmodern-js": () => import("./../../../src/pages/saasmodern.js" /* webpackChunkName: "component---src-pages-saasmodern-js" */),
  "component---src-pages-webapp-js": () => import("./../../../src/pages/webapp.js" /* webpackChunkName: "component---src-pages-webapp-js" */),
  "component---src-pages-webappcreative-js": () => import("./../../../src/pages/webappcreative.js" /* webpackChunkName: "component---src-pages-webappcreative-js" */)
}

